<template>
    <div class="space-y-4 px-4 py-2" id="contacts">
        <div class="text-xl font-medium">Контактные данные</div>
        <div class="space-y-2">
            <label
                    for="fio"
                    class="block text-base font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
                ФИО ребенка
            </label>
            <input
                    type="text"
                    name="fio"
                    id="fio"
                    v-model="slotsStore.patient.fio"
                    class="block w-full h-14 pl-4 border text-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
        </div>
        <div class="space-y-2" v-if="type !== 'order'">
            <label
                    for="phone"
                    class="block text-base font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
                Дата рождения ребенка
            </label>

            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-3 md:col-span-3">
                    <Listbox class="w-full" v-model="slotsStore.patient.birthday.day">
                        <div class="relative mt-1">
                            <ListboxButton
                                    class="relative w-full text-left cursor-default py-4 pl-3 md:pl-4 pr-4 border text-sm md:text-base rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            >
                <span class="block truncate">{{
                    slotsStore.patient.birthday.day
                        ? slotsStore.patient.birthday.day
                        : "День"
                    }}</span>
                                <span
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-0 md:pr-2"
                                >
                  <SelectorIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                  />
                </span>
                            </ListboxButton>

                            <transition
                                    leave-active-class="transition duration-100 ease-in"
                                    leave-from-class="opacity-100"
                                    leave-to-class="opacity-0"
                            >
                                <ListboxOptions
                                        class="absolute mt-2 z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-base"
                                >
                                    <ListboxOption
                                            v-slot="{ active, selected }"
                                            v-for="item in 31"
                                            :key="item"
                                            :value="item"
                                            as="template"
                                    >
                                        <li
                                                :class="[
                        active
                          ? 'bg-amber-100 text-amber-900'
                          : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-4 text-center pr-2 md:pr-4',
                      ]"
                                        >
                      <span
                              :class="[
                          selected ? 'font-medium' : 'font-normal',
                          'block truncate',
                        ]"
                      >{{ item }}</span
                      >
                                            <span
                                                    v-if="selected"
                                                    class="absolute inset-y-0 left-0 flex items-center pl-2 text-amber-600"
                                            >
                        <CheckIcon
                                class="w-2 md:w-4 h-4 w-2 md:w-4"
                                aria-hidden="true"
                        />
                      </span>
                                        </li>
                                    </ListboxOption>
                                </ListboxOptions>
                            </transition>
                        </div>
                    </Listbox>
                </div>
                <div class="col-span-4 md:col-span-4">
                    <Listbox class="w-full" v-model="slotsStore.patient.birthday.month">
                        <div class="relative mt-1">
                            <ListboxButton
                                    class="relative w-full text-left cursor-default py-4 pl-3 md:pl-4 pr-4 border text-sm md:text-base rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            >
                <span class="block truncate">{{
                    slotsStore.patient.birthday.month ? titleMonth : "Месяц"
                    }}</span>
                                <span
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-0 md:pr-2"
                                >
                  <SelectorIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                  />
                </span>
                            </ListboxButton>

                            <transition
                                    leave-active-class="transition duration-100 ease-in"
                                    leave-from-class="opacity-100"
                                    leave-to-class="opacity-0"
                            >
                                <ListboxOptions
                                        class="absolute mt-2 z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-base"
                                >
                                    <ListboxOption
                                            v-slot="{ active, selected }"
                                            v-for="item in listMonth"
                                            :key="item.id"
                                            :value="item.code"
                                            as="template"
                                    >
                                        <li
                                                :class="[
                        active
                          ? 'bg-amber-100 text-amber-900'
                          : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-4 text-center pr-2 md:pr-4',
                      ]"
                                        >
                      <span
                              :class="[
                          selected ? 'font-medium' : 'font-normal',
                          'block truncate',
                        ]"
                      >{{ item.label }}</span
                      >
                                            <span
                                                    v-if="selected"
                                                    class="absolute inset-y-0 left-0 flex items-center pl-2 text-amber-600"
                                            >
                        <CheckIcon
                                class="w-2 md:w-4 h-4 w-2 md:w-4"
                                aria-hidden="true"
                        />
                      </span>
                                        </li>
                                    </ListboxOption>
                                </ListboxOptions>
                            </transition>
                        </div>
                    </Listbox>
                </div>
                <div class="col-span-5 md:col-span-5">
                    <Listbox class="w-full" v-model="slotsStore.patient.birthday.year">
                        <div class="relative mt-1">
                            <ListboxButton
                                    class="relative w-full text-left cursor-default py-4 pl-3 md:pl-4 pr-4 border text-sm md:text-base rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            >
                <span class="block truncate">{{
                    slotsStore.patient.birthday.year
                        ? slotsStore.patient.birthday.year
                        : "Год рождения"
                    }}</span>
                                <span
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-0 md:pr-2"
                                >
                  <SelectorIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                  />
                </span>
                            </ListboxButton>

                            <transition
                                    leave-active-class="transition duration-100 ease-in"
                                    leave-from-class="opacity-100"
                                    leave-to-class="opacity-0"
                            >
                                <ListboxOptions
                                        class="absolute mt-2 z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-base"
                                >
                                    <ListboxOption
                                            v-slot="{ active, selected }"
                                            v-for="item in [...listYear]"
                                            :key="item"
                                            :value="item"
                                            as="template"
                                    >
                                        <li
                                                :class="[
                        active
                          ? 'bg-amber-100 text-amber-900'
                          : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-4 text-center pr-2 md:pr-4',
                      ]"
                                        >
                      <span
                              :class="[
                          selected ? 'font-medium' : 'font-normal',
                          'block truncate',
                        ]"
                      >{{ item }}</span
                      >
                                            <span
                                                    v-if="selected"
                                                    class="absolute inset-y-0 left-0 flex items-center pl-2 text-amber-600"
                                            >
                        <CheckIcon
                                class="w-2 md:w-4 h-4 w-2 md:w-4"
                                aria-hidden="true"
                        />
                      </span>
                                        </li>
                                    </ListboxOption>
                                </ListboxOptions>
                            </transition>
                        </div>
                    </Listbox>
                </div>
            </div>
        </div>
        <div class="space-y-2">
            <label
                    for="phone"
                    class="block text-base font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
                Телефон
            </label>
            <input
                    v-maska="'+7 (###) ###-##-##'"
                    placeholder="+7 (___) __-__-__"
                    type="text"
                    name="phone"
                    id="phone"
                    v-model="slotsStore.patient.phone"
                    class="block w-full h-14 pl-4 border text-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
        </div>

        <div class="space-y-2" v-if="slotsStore.typeVisit === 'home'">
            <label
                    for="fio"
                    class="block text-base font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
                <div class="flex w-100 justify-between">
                    <div>Ваш адрес</div>
                    <div
                            @click="clearHome()"
                            class="text-black opacity-50 cursor-pointer"
                    >
                        изменить
                    </div>
                </div>
            </label>
            <textarea
                    :disabled="true"
                    type="text"
                    name="address"
                    id="address"
                    v-model="slotsStore.patient.address"
                    class="block w-full p-2 pl-4 border text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-4 md:col-span-4">
                    <label
                            for="apartment"
                            class="block text-base font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                        Подъезд
                    </label>
                    <input
                            type="text"
                            name="frame"
                            id="frame"
                            v-model="slotsStore.patient.front"
                            class="block w-full h-14 pl-4 border text-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                </div>
                <div class="col-span-4 md:col-span-4">
                    <label
                            for="front"
                            class="block text-base font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                        Квартира
                    </label>
                    <input
                            type="text"
                            name="front"
                            id="front"
                            v-model="slotsStore.patient.apartment"
                            class="block w-full h-14 pl-4 border text-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                </div>
                <div class="col-span-4 md:col-span-4">
                    <label
                            for="apartment"
                            class="block text-base font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                        Этаж
                    </label>
                    <input
                            type="text"
                            name="apartment"
                            id="apartment"
                            v-model="slotsStore.patient.floor"
                            class="block w-full h-14 pl-4 border text-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                </div>
            </div>

            <div class="space-y-2">
                <label
                        for="front"
                        class="block text-base font-medium text-gray-900 sm:mt-px sm:pt-2"
                >
                    Комментарий к адресу
                </label>
                <textarea
                        type="text"
                        name="caddress"
                        id="caddress"
                        rows="3"
                        placeholder="Не работает домофон, консьерж, закрытый двор и прочее"
                        v-model="slotsStore.patient.caddress"
                        class="block w-full px-4 py-4 border text-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
            </div>
        </div>
        <div class="space-y-2">
            <Listbox class="w-full" v-model="slotsStore.patient.reason">
                <div class="relative mt-1">
                    <ListboxButton
                            class="relative w-full text-left cursor-default py-4 pl-3 md:pl-4 pr-4 border text-sm md:text-base rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                <span class="block truncate">{{
                    slotsStore.patient.reason
                        ? slotsStore.patient.reason
                        : "Причина обращения"
                    }}</span>
                        <span
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-0 md:pr-2"
                        >
                  <SelectorIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                  />
                </span>
                    </ListboxButton>

                    <transition
                            leave-active-class="transition duration-100 ease-in"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0"
                    >
                        <ListboxOptions
                                class="absolute mt-2 z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-base"
                        >
                            <ListboxOption
                                    v-slot="{ active, selected }"
                                    v-for="item in ['Плановый прием', 'Вакцинация', 'По болезни']"
                                    :key="item"
                                    :value="item"
                                    as="template"
                            >
                                <li
                                        :class="[
                        active
                          ? 'bg-amber-100 text-amber-900'
                          : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-4 text-center pr-2 md:pr-4',
                      ]"
                                >
                      <span
                              :class="[
                          selected ? 'font-medium' : 'font-normal',
                          'block truncate',
                        ]"
                      >{{ item }}</span
                      >
                                    <span
                                            v-if="selected"
                                            class="absolute inset-y-0 left-0 flex items-center pl-2 text-amber-600"
                                    >
                        <CheckIcon
                                class="w-2 md:w-4 h-4 w-2 md:w-4"
                                aria-hidden="true"
                        />
                      </span>
                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                </div>
            </Listbox>


            <textarea
                    id="comment"
                    name="comment"
                    placeholder="Подробное описание обращения"
                    @click="scrollTo('comment', 'dialog-app', 50)"
                    v-model="slotsStore.patient.comment"
                    rows="3"
                    class="block w-full px-4 py-4 border text-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
        </div>
    </div>
</template>

<script>
import {useSlots} from "../../store/slots";
import {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
} from "@headlessui/vue";
import {CheckIcon, SelectorIcon} from "@heroicons/vue/solid";
import {scrollTo} from "../../libs/ui";
import {useHome} from "~/store/home";

export default {
    props: ["type"],
    components: {
        Listbox,
        ListboxLabel,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        CheckIcon,
        SelectorIcon,
    },

    setup() {
        const slotsStore = useSlots();
        const homeStore = useHome();

        return {
            scrollTo,
            slotsStore,
            homeStore,
        };
    },
    computed: {
        listYear() {
            let listYear = [];
            let now = new Date();
            const maxAge = 90;
            for (let i = 0; i < maxAge; i++) {
                listYear.push(+now.getFullYear() - i);
            }
            return listYear;
        },
        listMonth() {
            return [
                {label: "Январь", labelSelect: "Января", code: 1},
                {label: "Февраль", labelSelect: "Февраля", code: 2},
                {label: "Март", labelSelect: "Марта", code: 3},
                {label: "Апрель", labelSelect: "Апреля", code: 4},
                {label: "Май", labelSelect: "Мая", code: 5},
                {label: "Июнь", labelSelect: "Июня", code: 6},
                {label: "Июль", labelSelect: "Июля", code: 7},
                {label: "Август", labelSelect: "Августа", code: 8},
                {label: "Сентябрь", labelSelect: "Сентября", code: 9},
                {label: "Октябрь", labelSelect: "Октября", code: 10},
                {label: "Ноябрь", labelSelect: "Ноября", code: 11},
                {label: "Декабрь", labelSelect: "Декабря", code: 12},
            ];
        },
        titleMonth() {
            return this.listMonth.find(
                (item) => item.code == this.slotsStore.patient.birthday.month
            ).labelSelect;
        },
    },
    methods: {
        clearHome() {
            this.slotsStore.patient.address = "";
            this.slotsStore.target = null;
            this.slotsStore.mapApprove = null;
            this.slotsStore.patient.address = "";
            this.slotsStore.date = null;
            this.slotsStore.time = null;
            this.slotsStore.calendar = [];
            this.homeStore.address = null;
            this.homeStore.zone = null;
        },
    },
};
</script>
