<template>
  <div
    class="border flex border-[#F0EAEA] py-4 px-5 leading-6 rounded-lg items-center justify-between text-xl cursor-pointer"
    v-if="priceFiltered"
  >
    <span class="text-base md:text-xl">{{ price.attributes.title }}</span>
    <span
      class="ml-7 py-2 px-4 bg-[#FEF1F7] rounded-full whitespace-nowrap text-pink-500 text-base"
      >{{ priceFiltered }} ₽</span
    >
  </div>
</template>

<script>
import { useClinics } from "~/store/clinics";

export default {
  props: ["price"],
  setup() {
    const clinicsStore = useClinics();

    return {
      clinicsStore,
    };
  },
  computed: {
    priceFiltered() {
      const price = this.price?.attributes.price.find(
        (item) =>
          item?.clinic?.data?.attributes?.clinicId ===
          this.clinicsStore.clinicId
      );
      return price?.Price;
    },
  },
};
</script>
