<template>
    <div class="px-4 md:px-8" style="margin-top: -50px;">
        <div class="text-center">
            <img class="inline-block" style="width: 155px;" src="/complite.png" alt="">
            <div class="mt-5">
                <h2 class="xl:w-1/2 font-bold inline-block px-5 md:px-0" style="font-size: 20px;">
                    Вы оформили вызов педиатра&nbsp;на&nbsp;дом
                </h2>
            </div>
        </div>
        <div class="mt-5 text-center sm:mt-5 border-purple-100 border-2 rounded-2xl p-4">
            <div class="text-left">
                <div class="flex justify-between">
                    <div class="w-32 opacity-50">Адрес:</div>
                    <div class="text-right">{{ slotsStore.patient.address }}</div>
                </div>
            </div>
            <div class="mt-4 text-left">
                <div class="flex justify-between">
                    <div class="w-32 opacity-50">
                        Врач приедет:
                    </div>
                    <div class="text-right">
                        {{ createDateText(slotsStore.date) }},
                        {{
                        addMinutes(
                            createTimeText(slotsStore.time),
                            homeStore.zone.delay
                        )
                        }}
                        -
                        {{
                        addMinutes(createTimeText(slotsStore.time), homeStore.zone.time)
                        }}
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <div class="flex justify-between text-left">
                    <div class="w-32 opacity-50">Стоимость:</div>
                    <div class="text-right">{{ numberWithSpaces(homeStore.zone.cost) }} р</div>
                </div>
            </div>
        </div>

        <div
                class="mt-5 text-center uppercase cursor-pointer items-center justify-center px-6 py-4 border border-pink-600 font-bold rounded-full text-sm xl:text-base  text-pink-600 bg-white transition-colors w-100"
                @click="cancelBook(loader)"

        >
                <span class="xs:hidden">{{
                    loader ? "Отменяем..." : "Отменить запись"
                    }}
                </span>
            <span class="hidden xs:inline">{{
                loader ? "Отменяем..." : "Отменить"
                }}
                </span>
        </div>

        <div class="text-center mt-3 px-5">
            <span class="font-xs text-stone-700 opacity-40 hidden">Если оплата не пройдёт, то вызов будет отменён автоматически.</span>
        </div>


        <div class="mt-5 hidden" v-if="slotsStore.cachBookLink">
            <div
                    @click="cach"
                    class="cursor-pointer mt-5 w-100 bg-pink-600 border border-transparent rounded-full py-4 px-8 flex font-bold items-center justify-center  text-sm xl:text-base  text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    :class="{
                      'bg-[#F1D6CE] border-[#F1D6CE]': !loader,
                      'bg-gray-200 border-transparent cursor-not-allowed': loader,
                    }"
            >
                Оплатить
            </div>
        </div>

        <template v-if="false">
            <div class="md:mt-32" v-if="slotsStore.cachBookLink">
                <div
                        @click="cach"
                        class="flex cursor-pointer mt-5 w-100 bg-pink-600 border border-transparent rounded-full py-3 px-8 flex font-medium items-center justify-between  text-sm xl:text-base uppercase hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                    <div class="text-white">Оплатить</div>
                    <div class="bg-white border border-white font-medium px-4 p-2 rounded-full text-xs text-black">
                        {{ numberWithSpaces(homeStore.zone.cost) }} р
                    </div>
                </div>
            </div>
            <div class="text-center mt-3 px-5">
                <span class="font-xs text-stone-700 opacity-40">Времени на оплату </span><span
                    class="text-black">30 мин.</span>
            </div>
        </template>

        <!--        <div class="w-100 mt-5 whitespace-nowrap cursor-pointer w-full inline-flex items-center font-bold justify-center py-4 px-8 border rounded-full  text-sm xl:text-base  text-black/70"-->
        <!--             @click="slotsStore.open = false">-->
        <!--            Вернуться на сайт-->
        <!--        </div>-->
    </div>
</template>

<script>
import * as DateBook from "datebook";
import {createDateText, createTimeText, addMinutes} from "~/libs/date";
import {useSlots} from "~/store/slots";
import {useDocs} from "~/store/docs";
import {useClinics} from "~/store/clinics";
import {usePrices} from "~/store/price";
import {numberWithSpaces} from "~/libs/ui";
import {useHome} from "~/store/home";

export default {
    data() {
        return {
            loader: false,
        };
    },
    setup() {
        const slotsStore = useSlots();
        const docsStore = useDocs();
        const clinicsStore = useClinics();
        const priceStore = usePrices();
        const homeStore = useHome();

        return {
            homeStore,
            priceStore,
            docsStore,
            slotsStore,
            clinicsStore,
            createDateText,
            createTimeText,
            numberWithSpaces,
            addMinutes,
        };
    },
    computed: {
        docName() {
            const doc = this.docsStore.getDocById(this.slotsStore.docId);
            return doc?.attributes?.lname + " " + doc?.attributes?.fname;
        },
        clinicAddress() {
            return this.clinicsStore.getClinicById(this.clinicsStore.clinicId)
                .attributes.address;
        },
    },
    methods: {
        async cancelBook(loader) {
            if (!loader) {
                this.loader = true;
                await this.slotsStore.cancelBook();
                setTimeout(() => {
                    this.slotsStore.open = false;
                    this.slotsStore.complete = false;
                    this.slotsStore.clearVisit();
                    this.loader = false;
                }, 3000);
            }
        },
        cach() {
            window.open(this.slotsStore.cachBookLink);
        },
        cal() {
            let start = new Date(this.slotsStore.time);
            let end = start;
            end.setTime(start.getTime() + 60 * 60 * 1000);

            const config = {
                title: "Вы записаны на прием",
                location: this.clinicAddress,
                description: "Врач: " + this.docName,
                start,
                end,
            };

            const googleCalendar = new DateBook.GoogleCalendar(config);
            window.location = googleCalendar.render();
        },
    },
};
</script>
