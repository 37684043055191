<template>
    <TransitionRoot as="template" :show="slotsStore.open">
        <Dialog
                as="div"
                class="fixed inset-0 overflow-hidden dialog-app z-20"
                @close="slotsStore.open = false"
        >
            <div class="absolute inset-0 overflow-hidden">
                <DialogOverlay class="absolute inset-0 bg-opacity-90 bg-orange-50"/>

                <div
                        class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-16"
                >
                    <TransitionChild
                            as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full"
                            enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0"
                            leave-to="translate-x-full"
                    >
                        <div class="pointer-events-auto w-screen max-w-[496px]">
                            <form
                                    class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                            >
                                <div class="flex-1">
                                    <!-- Header -->
                                    <div class="px-4 py-4 mb-8">
                                        <div class="flex items-center justify-between space-x-3">
                                            <div class="space-y-1">
                                                <DialogTitle
                                                        class="text-2xl font-normal leading-8 text-gray-900"
                                                >
                                                    {{ title }}
                                                </DialogTitle>
                                            </div>
                                            <div class="flex relative items-center bg-white rounded">
                                                <button
                                                        type="button"
                                                        class="text-gray-300 hover:text-black outline-none p-2"
                                                        @click="
                                                            slotsStore.open = false;
                                                            slotsStore.typeVisit = 'visit';
                                                            slotsStore.target = null;
                                                            homeStore.zone = null;
                                                          "
                                                >
                                                    <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <template v-if="!slotsStore.complete">
                                        <div class="space-y-6">
                                            <template v-if="showQuize">
                                                <SlotQuize/>
                                            </template>

                                            <template v-else-if="showMap && showAddress">
                                                <ClientOnly>
                                                    <DialogHomeMap/>
                                                    <DialogHomeAddressY/>
                                                    <DialogHomeDebug v-if="uiStore.debugMode"/>
                                                </ClientOnly>
                                            </template>

                                            <template v-if="slotsStore.target !== null">
                                                <template v-if="showTypeVisit">
                                                    <TypeVisit/>
                                                </template>
                                                <div class="px-0" id="calendar" v-if="showCalendar">
                                                    <SlotCalendar/>
                                                </div>

                                                <div class="px-4 pt-0 mt-0" id="times" v-if="showTime">
                                                    <SlotTime/>
                                                </div>
                                            </template>

                                            <template v-if="showContacts">
                                                <SlotContacts/>
                                            </template>
                                        </div>
                                    </template>

                                    <template v-if="showComplite">

                                        <template v-if="slotsStore.typeVisit !== 'home'">
                                            <div class="pt-5 px-4 md:px-8">
                                                <SlotComplite/>
                                            </div>
                                            <div class="mt-3 sm:mt-5"></div>
                                        </template>
                                        <template v-else>
                                            <DialogHomeComplite/>
                                        </template>
                                        <div class="mt-5 sm:mt-6"></div>
                                    </template>
                                </div>

                                <!-- Action buttons -->
                                <template v-if="showActions">
                                    <SlotActions/>
                                </template>
                                <template v-else-if="showHomeActions">
                                    <DialogHomeAction/>
                                </template>
                            </form>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {useSlots} from "../../store/slots";
import {useDocs} from "../../store/docs";

import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";

import {XIcon} from "@heroicons/vue/outline";
import TypeVisit from "../Sections/TypeVisit";
import Contacts from "./Contacts";
import {scrollTo} from "../../libs/ui";
import {useHome} from "~/store/home";
import {useUi} from "~/store/ui";

export default {
    props: ["type"],
    setup() {
        const docsStore = useDocs();
        const slotsStore = useSlots();
        const homeStore = useHome();
        const uiStore = useUi();

        return {
            homeStore,
            docsStore,
            slotsStore,
            scrollTo,
            uiStore,
        };
    },

    data() {
        return {
            name: "Врач",
        };
    },
    computed: {
        title() {
            if (!this.slotsStore.complete) {
                return this.slotsStore.typeVisit === "home"
                    ? "Выезд педиатра на дом"
                    : "Запись на прием";
            } else if (this.slotsStore.type === "home") {
                return "";
            } else if (this.order) {
                return "Заявка успешно отправлена";
            } else {
                return `Успешная запись на ${
                    this.slotsStore.typeVisit !== "online"
                        ? "прием"
                        : "онлайн-консультацию"
                }`;
            }
        },
        showQuize() {
            return this.slotsStore.type === "all";
        },
        showMap() {
            return this.slotsStore.type === "home";
        },
        showAddress() {
            return this.slotsStore.type === "home";
        },
        showTypeVisit() {
            return this.slotsStore.target !== "home";
        },
        showCalendar() {
            return this.slotsStore.target !== null;
        },
        showTime() {
            return this.slotsStore.date && this.slotsStore.target !== null;
        },
        showContacts() {
            return this.slotsStore.date && this.slotsStore.time;
        },
        showActions() {
            return (
                !this.slotsStore.complete &&
                ((this.slotsStore.typeVisit === "home" && this.slotsStore.mapApprove) ||
                    this.slotsStore.typeVisit !== "home")
            );
        },
        showHomeActions() {
            return (
                !this.slotsStore.complete &&
                this.slotsStore.typeVisit === "home" &&
                !this.slotsStore.mapApprove
            );
        },
        showComplite() {
            return this.slotsStore.complete;
        },
    },
    watch: {
        date() {
        },
    },
    components: {
        DialogOverlay,
        DialogTitle,
        TransitionRoot,
        TransitionChild,
        XIcon,
        Contacts,
        Dialog,
        TypeVisit,
    },
};
</script>
