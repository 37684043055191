<template>
  <div
    class="border flex border-[#F0EAEA] py-4 px-5 leading-6 rounded-lg items-center justify-between text-xl cursor-pointer"
  >
    <div class="flex items-start w-full">
      <figure class="pr-4">
        <img
          class="w-16 h-16 rounded-full object-cover object-top"
          :src="photo"
          :alt="`${doc?.attributes.lname} ${doc?.attributes.fname}`"
        />
      </figure>
      <div class="grow">
        <span class="text-sm text-pink-300 font-medium">{{
          doc?.attributes?.special
        }}</span>
        <div class="text-md block hover:text-pink-500 text-base mb-2">
          {{ doc.attributes.lname }} {{ doc.attributes.fname }}
        </div>
        <span class="flex justify-between items-center w-full">
          <span
            v-if="doc?.attributes.consultation"
            class="py-2 px-4 bg-[#FEF1F7] rounded-full whitespace-nowrap text-pink-500 text-sm"
            >{{ doc?.attributes?.consultation }} ₽</span
          >
          <span class="text-sm text-black/40 ml-auto hidden"
            >с 14 сентября</span
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { useDocs } from "../../../store/docs";

export default {
  props: ["doc"],
  setup() {
    const config = useRuntimeConfig();
    const apiBase = config.public.apiBase;
    const docsStore = useDocs();

    return {
      docsStore,
      apiBase,
    };
  },
  computed: {
    photo() {
      const image = this.docsStore.getPhotoMini(this.doc.id);
      return image ? image : this.docsStore.getPhotoPlaceholder(this.doc);
    },
  },
};
</script>
