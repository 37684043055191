<template>
  <fieldset>
    <div class="type-visit px-4 sm:px-8 mb-4">
      <div class="flex justify-around sm:mt-0 border-b">
        <div class="flex">
          <input
            id="public-access"
            name="privacy"
            value="visit"
            v-model="storeSlots.typeVisit"
            aria-describedby="public-access-description"
            type="radio"
            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 hidden type-visit__input"
          />
          <label
            for="public-access"
            class="font-bold text-sm uppercase text-gray-900 type-visit__label py-5 px-8"
          >
            В клинике
          </label>
        </div>
        <div class="flex hidden">
          <input
            v-model="storeSlots.typeVisit"
            id="at-home"
            name="privacy"
            value="home"
            aria-describedby="at-home-description"
            type="radio"
            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 hidden type-visit__input"
          />
          <label
            for="at-home"
            class="font-bold text-sm uppercase text-gray-900 type-visit__label py-4 px-6"
          >
            На дому
          </label>
        </div>

        <div class="flex">
          <input
            v-model="storeSlots.typeVisit"
            value="online"
            id="restricted-access"
            name="privacy"
            aria-describedby="restricted-access-description"
            type="radio"
            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 hidden type-visit__input"
          />
          <label
            for="restricted-access"
            class="font-bold text-sm uppercase text-gray-900 type-visit__label py-4 px-6"
          >
            Онлайн
          </label>
        </div>
      </div>
    </div>
  </fieldset>
</template>
<script>
import { useSlots } from "../../store/slots";

export default {
  setup() {
    const storeSlots = useSlots();

    return {
      storeSlots,
    };
  },
};
</script>
<style lang="scss">
.type-visit {
  &__label {
    cursor: pointer;
    border-bottom: 2px solid transparent;
  }

  &__input {
    &:checked + label {
      color: #d4026e;
      border-color: #d4026e;
    }
  }
}
</style>
