<template>
  <div class="px-4">
    <div class="step-1 mb-8">
      <div class="text-xl font-medium mb-5">Вы знаете фамилию врача?</div>
      <div
        class="flex w-full border border-pink-600 rounded-full uppercase overflow-hidden text-center text-sm font-medium text-pink-600 cursor-pointer"
      >
        <span
          @click="
            quizeWay = 'doc';
            scrollTo('listDocs', 'dialog-app', 50);
            slotsStore.unsetTarget();
          "
          :class="{
            'hover:text-white bg-pink-600 text-white': quizeWay === 'doc',
          }"
          class="w-1/2 py-4 px-4 hover:text-black"
        >
          Да
        </span>

        <span
          @click="
            quizeWay = 'specialVsService';
            slotsStore.unsetTarget();
          "
          :class="{
            'hover:text-white bg-pink-600 text-white':
              quizeWay === 'specialVsService' ||
              quizeWay === 'special' ||
              quizeWay === 'price',
          }"
          class="w-1/2 py-4 px-4 hover:text-black border-l border-pink-600"
        >
          Нет
        </span>
      </div>
    </div>

    <template
      v-if="
        quizeWay === 'specialVsService' ||
        quizeWay === 'special' ||
        quizeWay === 'price'
      "
    >
      <div class="step-2 mb-8">
        <div class="text-xl font-medium mb-5">Я знаю</div>

        <div
          class="flex w-full border border-pink-600 rounded-full uppercase overflow-hidden text-center text-sm font-medium text-pink-600 cursor-pointer"
        >
          <span
            @click="
              quizeWay = 'special';
              scrollTo('specialList', 'dialog-app', 50);
              slotsStore.unsetTarget();
            "
            :class="{
              'hover:text-white bg-pink-600 text-white': quizeWay === 'special',
            }"
            class="w-1/2 py-4 px-4 hover:text-black"
            >Специализацию</span
          >
          <span
            @click="
              quizeWay = 'price';
              scrollTo('priceList', 'dialog-app', 50);
              slotsStore.unsetTarget();
            "
            :class="{
              'hover:text-white bg-pink-600 text-white': quizeWay === 'price',
            }"
            class="w-1/2 py-4 px-4 hover:text-black border-l border-pink-600"
            >Услугу</span
          >
        </div>
      </div>
    </template>

    <div class="step-3 mb-4" id="listOfList">
      <template v-if="quizeWay === 'doc' && !selectedDoc">
        <div class="text-xl font-medium mb-5 pt-5" id="listDocs">
          Выберите врача
        </div>
        <div class="min-h-[80vh]">
          <div class="mb-6">
            <SearchInput
              placeholder="Поиск по ФИО"
              type="docs"
              :isSlot="true"
              :scroll="false"
              class="border border-[#F0EAEA]"
            />
          </div>
          <ul class="space-y-2">
            <li
              v-for="doc in docsStore.filterListDocs"
              :key="doc.id"
              @click="selectedDoc = doc"
              v-show="!doc?.attributes?.hide"
            >
              <SlotCardDoc :doc="doc" />
            </li>
          </ul>
        </div>
      </template>
      <template v-else-if="selectedDoc">
        <div
          class="text-xl font-medium mb-5 pt-5 flex justify-between items-center"
        >
          Ваш врач
          <span
            @click="
              selectedDoc = null;
              slotsStore.unsetTarget();
              docsStore.textSearch = '';
            "
            class="text-sm text-pink-500 underline font-light hover:text-pink-700 transition-colors cursor-pointer"
          >
            Изменить
          </span>
        </div>

        <SlotCardDoc :doc="selectedDoc" />
      </template>

      <template v-if="quizeWay === 'special' && !selectedSpecial">
        <div class="text-xl font-medium mb-5 pt-5" id="specialList">
          Выберите специализацию
        </div>
        <div>
          <div class="min-h-[80vh]">
            <div class="mb-6">
              <SearchInput
                placeholder="Поиск по специализации"
                type="special"
                :scroll="false"
                class="border border-[#F0EAEA]"
              />
            </div>
            <ul class="space-y-2">
              <template
                v-for="special in alphabetFilterSpecialList"
                :key="special.id"
              >
                <li
                  v-if="special && special.id !== null"
                  @click="selectedSpecial = special"
                >
                  <SlotCardSpecial :special="special" />
                </li>
              </template>
            </ul>
          </div>
        </div>
      </template>
      <template v-else-if="quizeWay === 'special' && selectedSpecial">
        <div
          class="text-xl font-medium pt-5 mb-5 flex justify-between items-center"
        >
          Специализация
          <span
            @click="
              selectedSpecial = null;
              slotsStore.unsetTarget();
              specialsStore.textSearch = '';
            "
            class="text-sm text-pink-500 underline font-light hover:text-pink-700 transition-colors cursor-pointer"
          >
            Изменить
          </span>
        </div>

        <SlotCardSpecial :special="selectedSpecial" />
      </template>

      <template v-if="quizeWay === 'price' && !selectedPrice">
        <div class="text-xl font-medium mb-5 pt-5" id="priceList">
          Выберите услугу
        </div>
        <div>
          <div class="min-h-[80vh]">
            <div class="mb-6">
              <SearchInput
                placeholder="Поиск по услуге"
                type="price"
                :scroll="false"
                class="border border-[#F0EAEA]"
              />
            </div>
            <ul class="space-y-2">
              <li
                v-for="price in pricesStore.filterListPrices"
                :key="price.id"
                @click="selectedPrice = price"
              >
                <SlotCardPrice :price="price" />
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-else-if="quizeWay === 'price' && selectedPrice">
        <div
          class="text-xl font-medium mb-5 pt-5 flex justify-between items-center"
        >
          Услуга
          <span
            @click="
              selectedPrice = null;
              slotsStore.unsetTarget();
              pricesStore.textSearch = '';
            "
            class="text-sm text-pink-500 underline font-light hover:text-pink-700 transition-colors cursor-pointer"
          >
            Изменить
          </span>
        </div>

        <SlotCardPrice :price="selectedPrice" />
      </template>
    </div>
  </div>
</template>

<script>
import { scrollTo } from "../../libs/ui";
import { useSlots } from "../../store/slots";
import { useDocs } from "../../store/docs";
import { usePrices } from "../../store/price";
import { useSpecials } from "../../store/specials";

export default {
  data() {
    return {
      quizeWay: null,
      selectedDoc: null,
      selectedSpecial: null,
      selectedPrice: null,
    };
  },
  async mounted() {
    this.slotsStore.target = null;
    this.quizeWay = null;
    this.selectedDoc = null;
    this.selectedSpecial = null;
    this.selectedPrice = null;

    if (!this.docsStore.listDocs.length) {
      await this.docsStore.fetchDocs();
    }
    if (!this.specialsStore.listSpecials.length) {
      await this.specialsStore.fetchSpecials();
    }
    if (!this.pricesStore.listPrices.length) {
      await this.pricesStore.fetchPrices();
    }
  },
  computed: {
    listPricesWithCost() {
      return this.pricesStore.filterListPrices.filter(
        (item) => item?.attributes?.price?.filter((item) => item.Price).length
      );
    },
    alphabetFilterSpecialList() {
      return this.specialsStore.filterListSpecials.sort((a, b) => {
        if (a.attributes.title < b.attributes.title) {
          return -1;
        }
        if (a.attributes.title > b.attributes.title) {
          return 1;
        }
        return 0;
      });
    },
  },
  watch: {
    async quizeWay(val) {
      this.selectedDoc = null;
      this.selectedSpecial = null;
      this.selectedPrice = null;
    },
    selectedDoc(val) {
      if (val !== null) {
        this.slotsStore.target = "doc";
        this.slotsStore.docId = val.id;
        scrollTo("listOfList", "dialog-app", 50);
      } else {
        this.slotsStore.docId = null;
      }
    },
    selectedPrice(val) {
      if (val !== null) {
        this.slotsStore.target = "price";
        this.slotsStore.priceId = val.id;
        scrollTo("listOfList", "dialog-app", 50);
      } else {
        this.slotsStore.priceId = null;
      }
    },
    selectedSpecial(val) {
      if (val !== null) {
        this.slotsStore.target = "special";
        this.slotsStore.specialId = val.id;
        scrollTo("listOfList", "dialog-app", 50);
      } else {
        this.slotsStore.specialId = null;
      }
    },
  },
  async setup() {
    const config = useRuntimeConfig();
    const apiBase = config.public.apiBase;

    const slotsStore = useSlots();
    const docsStore = useDocs();
    const pricesStore = usePrices();
    const specialsStore = useSpecials();

    return {
      scrollTo,
      slotsStore,
      docsStore,
      pricesStore,
      specialsStore,
      apiBase,
    };
  },
};
</script>
