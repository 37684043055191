<template>
  <div
    class="border flex border-[#F0EAEA] py-4 px-5 leading-6 rounded-lg items-center justify-between text-xl cursor-pointer"
  >
    <span class="text-base md:text-xl">{{ special.attributes.title }}</span>
    <!--        <span v-if="price?.attributes?.price[0]?.Price"-->
    <!--              class="ml-7 py-2 px-4 bg-[#FEF1F7] rounded-full whitespace-nowrap text-pink-500 text-base">от {{-->
    <!--                price?.attributes?.price[0].Price-->
    <!--            }} ₽</span>-->
  </div>
</template>

<script>
export default {
  props: ["special"],
};
</script>
