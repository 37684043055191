<template>
  <div
    class="flex items-center mt-2 home bg-stone-200 justify-center"
    id="map"
    style="margin-top: -30px"
  >
    <div v-if="showMap" class="w-full">
      <ClientOnly>
        <yandex-map
          :coords="coords"
          :zoom="zoom"
          :state="state"
          :options="options"
          :controls="controls"
          :behaviors="behaviors"
        >
          <ymap-marker
            :coords="homeStore.coords"
            marker-id="marker"
            v-if="homeStore.coords.length"
          />
          <ymap-marker
            v-for="(polygon, idx) of listPolygon"
            :coords="polygon.coords"
            :marker-id="`polygon-${idx}`"
            marker-type="Polygon"
            :marker-fill="{
              color: polygon.color,
              opacity: polygon.title === 'bounds' ? 0.05 : 0.5,
            }"
            :marker-stroke="{
              color: polygon.color,
              width: polygon.title === 'bounds' ? 2 : 1,
              opacity: 0.5,
            }"
          />
        </yandex-map>
      </ClientOnly>
    </div>
    <div v-else>Загрузка карты...</div>
  </div>
</template>

<script>
import { useHome } from "~/store/home";
import { useClinics } from "~/store/clinics";
import { useUi } from "~/store/ui";

export default {
  data() {
    return {
      listPolygon: [],
      showMap: false,
      state: {},
      options: {
        suppressMapOpenBlock: true,
      },
      controls: ["zoomControl"],
      behaviors: [], //"drag"
      bounds: [],
      rectangleBounds: [],
    };
  },
  setup() {
    const homeStore = useHome();
    const clinicStore = useClinics();
    const uiStore = useUi();

    const clinic = clinicStore.clinic;
    const coords = clinicStore.clinicCoords;
    const zoom =
      clinic.attributes && clinic.attributes.mapzoom
        ? clinic.attributes.mapzoom
        : 9;

    return {
      homeStore,
      clinicStore,
      uiStore,
      coords,
      zoom,
    };
  },
  watch: {
    "homeStore.coords": {
      handler(val) {
        this.zoom = 3;
        this.coords = val;
      },
    },
    "uiStore.debugMode": {
      handler(val) {
        if (this.uiStore.debugMode) {
          this.bounds = this.homeStore.boundedCompile(this.listPolygon);
          this.rectangleBounds = this.homeStore.getRectangleVertices(
            this.bounds[0],
            this.bounds[1]
          );

          this.listPolygon.push({
            title: "bounds",
            color: "#D3016E",
            coords: [[...this.rectangleBounds]],
          });
        }

        this.showMap = true;
      },
    },
  },

  async mounted() {
    const region = this.clinicStore.clinicRegion;
    if (region) {
      await this.homeStore.fetchZonesAdd(region);
      this.listPolygon = this.homeStore.zonePolygonByRegion();

      if (this.uiStore.debugMode) {
        this.bounds = this.homeStore.boundedCompile(this.listPolygon);
        this.rectangleBounds = this.homeStore.getRectangleVertices(
          this.bounds[0],
          this.bounds[1]
        );

        this.listPolygon.push({
          title: "bounds",
          color: "#D3016E",
          coords: [[...this.rectangleBounds]],
        });
      }

      this.showMap = true;
    }
  },
};
</script>
<style>
#map,
.home .ymap-container {
  width: 100%;
  height: 285px;
}
</style>
