<template>
  <div v-if="homeStore?.zone?.title" class="mx-4 bg-[#FDF8F7] p-5 border">
    <a
      :href="linkMap()"
      target="_blank"
      class="w-full inline-flex md:text-base text-white items-center text-xs uppercase justify-center bg-pink-500 rounded-full py-2 md:py-2 md:px-2 px-2 mb md:mb-0 font-bold md:tracking-wider"
      >Открыть Яндекс Карты</a
    >

    <br />
    <br />

    <b>Зона:</b> {{ homeStore.zone.title }}
    <br />

    <b>Стоимость:</b>
    {{
      homeStore?.zone?.cost
        ? numberWithSpaces(homeStore.zone.cost) + " руб."
        : "НЕТ СТОИМОСТИ"
    }}
    <br />
    <b>Слот:</b> {{ homeStore.zone.time }} мин.
    <br />
    <b>Задержка:</b> {{ homeStore.zone.delay }} мин.
    <br />
    <br />
    <b>Врачи:</b>
    <template v-if="homeStore?.zone?.docs?.data">
      <div v-for="item of homeStore.zone.docs.data">
        {{ item.attributes.fname }}
        {{ item.attributes.lname }}
      </div>
    </template>
  </div>
</template>

<script>
import { useHome } from "~/store/home";
import { useSlots } from "~/store/slots";
import { numberWithSpaces } from "~/libs/ui";

export default {
  setup() {
    const homeStore = useHome();
    const slotsStore = useSlots();

    return {
      homeStore,
      slotsStore,
      numberWithSpaces,
    };
  },
  methods: {
    linkMap() {
      const yandexMapsLink = `https://yandex.ru/maps/?text=${encodeURIComponent(
        this.slotsStore.patient.address
      )}`;
      return yandexMapsLink;
    },
  },
};
</script>
