<template>
    <div>
        <div class="mt-3 text-center sm:mt-5">
            <div class="mt-4" v-if="slotsStore.typeVisit === 'visit'">
                <div class="flex justify-start">
                    <div class="w-32 text-left font-bold">Клиника:</div>
                    <div>{{ clinicAddress }}</div>
                </div>
            </div>
            <div class="mt-4 text-left">
                <div class="flex justify-start" v-if="slotsStore.typeVisit === 'home'">
                    <div class="w-32 font-bold">Адрес:</div>
                    <div>{{ slotsStore.patient.address }}</div>
                </div>
            </div>
            <div class="mt-4 text-left">
                <div class="flex justify-start">
                    <div
                            class="w-32 font-bold"
                            v-if="slotsStore.typeVisit === 'home'"
                    >
                        Врач приедет:
                    </div>
                    <div class="w-32 font-bold" v-else>
                        Время
                        <template v-if="slotsStore.typeVisit !== 'online'">приема</template>
                        :
                    </div>
                    <div>
                        {{ createDateText(slotsStore.date) }},
                        <template v-if="slotsStore.target === 'home'">
                            {{
                            addMinutes(
                                createTimeText(slotsStore.time),
                                homeStore.zone.delay
                            )
                            }}
                            -
                            {{
                            addMinutes(createTimeText(slotsStore.time), homeStore.zone.time)
                            }}
                        </template>
                        <template v-else>
                            {{ createTimeText(slotsStore.time) }}
                        </template>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="slotsStore.typeVisit === 'home'">
                <div class="flex justify-start text-left">
                    <div class="w-32 font-bold">Стоимость:</div>
                    <div>{{ numberWithSpaces(homeStore.zone.cost) }} р</div>
                </div>
            </div>
            <div class="mt-4" v-if="slotsStore.typeVisit === 'home' && false">
                <div class="flex justify-start text-left">
                    <div class="font-bold">
                        В ближайшее время вам придет ссылка для оплаты выезда
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="slotsStore.target !== 'home'">
                <div class="flex justify-start">
                    <div class="w-32 text-left font-bold">Врач:</div>
                    <div>
                        {{ docName }}
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="slotsStore.target === 'price'">
                <div class="flex justify-start">
                    <div class="text-left font-bold">Стоимость:</div>
                    <div>
                        {{
                        priceStore.priceInClinic(
                            slotsStore.priceId,
                            clinicsStore.clinicId
                        )
                        }}
                        рублей
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="slotsStore.target === 'special'">
                <div class="flex justify-start">
                    <div class="w-32 text-left font-bold">Стоимость:</div>
                    <div>
                        {{ docsStore.getDocById(slotsStore.docId).attributes.consultation }}
                        рублей
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <div
                    @click="cal"
                    class="inline-flex cursor-pointer items-center justify-center px-6 py-2 border border-pink-600 rounded-full text-sm xl:text-base font-medium text-pink-600 bg-white hover:text-black transition-colors"
            >
                Добавить в календарь
            </div>
        </div>

        <div class="mt-4" v-if="slotsStore.cachBookLink">
            <div
                    @click="cach"
                    class="inline-flex cursor-pointer items-center justify-center px-6 py-2 border border-pink-600 rounded-full text-sm xl:text-base font-medium text-pink-600 bg-white hover:text-black transition-colors"
            >
                Оплатить
            </div>
        </div>
        <br/>

        <div class="mt-2 mb-8" v-if="slotsStore.typeVisit === 'visit'">
            <a href="/contacts" class="underline" target="_blank">Как добраться?</a>
            <br/>
            <a href="/info/preparation" class="underline" target="_blank"
            >Подготовка к приему</a
            >
        </div>

        <template
                v-if="slotsStore.typeVisit === 'visit' && slotsStore.patient.reason ==='По болезни' && clinicsStore.clinic?.attributes?.backdoor">
            <CardAttention :text="clinicsStore.clinic?.attributes?.backdoor"/>
        </template>

        <div class="flex items-end space-x-4">
            <div
                    class="cursor-pointer mt-3 w-32 bg-pink-600 border border-transparent rounded-full py-4 px-8 flex uppercase items-center justify-center text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    @click="slotsStore.open = false"
            >
                Готово
            </div>
            <br/>
            <div
                    class="whitespace-nowrap cursor-pointer w-full inline-flex items-center uppercase justify-center py-4 px-8 border rounded-full text-sm font-medium text-black/70"
                    @click="cancelBook(loader)"
                    :class="{
          'bg-[#F1D6CE] border-[#F1D6CE]': !loader,
          'bg-gray-200 border-transparent cursor-not-allowed': loader,
        }"
            >
        <span class="xs:hidden">{{
            loader ? "Отменяем..." : "Отменить запись"
            }}</span>
                <span class="hidden xs:inline">{{
                    loader ? "Отменяем..." : "Отменить"
                    }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import * as DateBook from "datebook";
import {createDateText, createTimeText, addMinutes} from "../../libs/date";
import {useSlots} from "../../store/slots";
import {useDocs} from "../../store/docs";
import {useClinics} from "../../store/clinics";
import {usePrices} from "../../store/price";
import {numberWithSpaces} from "~/libs/ui";
import {useHome} from "~/store/home";

export default {
    data() {
        return {
            loader: false,
        };
    },
    setup() {
        const slotsStore = useSlots();
        const docsStore = useDocs();
        const clinicsStore = useClinics();
        const priceStore = usePrices();
        const homeStore = useHome();

        return {
            homeStore,
            priceStore,
            docsStore,
            slotsStore,
            clinicsStore,
            createDateText,
            createTimeText,
            numberWithSpaces,
            addMinutes,
        };
    },
    computed: {
        docName() {
            const doc = this.docsStore.getDocById(this.slotsStore.docId);
            return doc?.attributes?.lname + " " + doc?.attributes?.fname;
        },
        clinicAddress() {
            return this.clinicsStore.getClinicById(this.clinicsStore.clinicId)
                .attributes.address;
        },
    },
    methods: {
        async cancelBook(loader) {
            if (!loader) {
                this.loader = true;
                await this.slotsStore.cancelBook();
                setTimeout(() => {
                    this.slotsStore.open = false;
                    this.slotsStore.complete = false;
                    this.slotsStore.clearVisit();
                    this.loader = false;
                }, 3000);
            }
        },
        cach() {
            window.open(this.slotsStore.cachBookLink);
        },
        cal() {
            let start = new Date(this.slotsStore.time);
            let end = start;
            end.setTime(start.getTime() + 60 * 60 * 1000);

            const config = {
                title: "Вы записаны на прием",
                location: this.clinicAddress,
                description: "Врач: " + this.docName,
                start,
                end,
            };

            const googleCalendar = new DateBook.GoogleCalendar(config);
            window.location = googleCalendar.render();
        },
    },
};
</script>
