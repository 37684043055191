<template>
  <div
    class="text-xl font-medium text-gray-900"
    v-if="slotsStore.date && slotsStore.timeList"
  >
    {{ slotsStore.typeVisit === "home" ? "Врач приедет в назначенное время" : "Приемы" }}
    <span class="whitespace-nowrap">{{ createDateText(slotsStore.date) }}</span>
  </div>

  <RadioGroup @click="scrollTo('contacts', 'contacts')" class="mt-2">
    <RadioGroupLabel class="sr-only"> Выберите время</RadioGroupLabel>
    <div
      class="grid grid-cols-2 gap-2 text-center py-4"
      :class="
        slotsStore.type === 'home'
          ? 'xl:gap-2 sm:grid-cols-2'
          : 'xl:gap-4 sm:grid-cols-4'
      "
    >
      <RadioGroupOption
        as="template"
        v-for="slot in slotsStore.timeList"
        @click="slotsStore.time = slot.value"
        :key="slot.name"
        :value="slot"
        v-slot="{ active, checked }"
      >
        <div
          :class="[
            'cursor-pointer focus:outline-none',
            slot.value === slotsStore.time
              ? 'bg-pink-600 border-transparent text-white hover:bg-pink-700'
              : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
            !uiStore.debugMode ? 'rounded-full' : '',
            'border py-2 px-3 flex items-center justify-center text-base font-medium uppercase sm:flex-1 hover:border-pink-500',
          ]"
        >
          <RadioGroupLabel as="p">
            <template v-if="slotsStore.type !== 'home'">
              {{ slot.name }}
            </template>
            <template v-else>
              <template v-if="uiStore.debugMode">
                <div style="font-size: 12px; text-transform: none">
                  <template v-for="docId in slot.listDocId">
                    {{ docsStore.getDocByMisId(docId).attributes.lname }}
                    <br />
                  </template>
                </div>
                {{ slot.name }} + {{ homeStore.zone.delay }} -
                {{ addMinutes(slot.name, homeStore.zone.delay) }}<br />
                {{ slot.name }} + {{ homeStore.zone.time }} -
                {{ addMinutes(slot.name, homeStore.zone.time) }}<br />
              </template>
              <template v-else>
                {{ addMinutes(slot.name, homeStore.zone.delay) }}
                -
                {{ addMinutes(slot.name, homeStore.zone.time) }}
              </template>
            </template>
          </RadioGroupLabel>
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>

<script>
import { createDateText, addMinutes } from "../../libs/date";
import { useSlots } from "../../store/slots";
import { useHome } from "../../store/home";
import { useUi } from "../../store/ui";
import { scrollTo } from "../../libs/ui";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { useDocs } from "~/store/docs";

export default {
  setup() {
    const slotsStore = useSlots();
    const homeStore = useHome();
    const uiStore = useUi();
    const docsStore = useDocs();

    return {
      docsStore,
      uiStore,
      slotsStore,
      homeStore,
      scrollTo,
      createDateText,
      addMinutes,
    };
  },
  computed: {},
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  },
};
</script>
