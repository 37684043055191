<template>
    <div class="mt-2 mb-8 rounded-lg bg-[#FDF8F7] p-4">
        <div class="flex">
            <div class="pr-3">

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.9899 1.96802C13.0129 1.96802 13.9599 2.48902 14.5019 3.32702L14.6049 3.49902L21.7049 15.749L21.7669 15.875C21.9443 16.3137 22.0154 16.7881 21.9744 17.2595C21.9334 17.7308 21.7815 18.1859 21.5311 18.5873C21.2807 18.9888 20.9389 19.3253 20.5336 19.5695C20.1282 19.8136 19.6709 19.9584 19.1989 19.992L18.9999 20H4.99995L4.95095 19.997L4.83895 19.999C4.39355 19.9761 3.95887 19.8542 3.56653 19.6421C3.1742 19.43 2.83409 19.1331 2.57095 18.773L2.46195 18.613C2.22379 18.2388 2.07157 17.8165 2.01624 17.3764C1.9609 16.9364 2.00384 16.4895 2.14195 16.068L2.21395 15.874L2.27395 15.749L9.36595 3.51602C9.62534 3.04705 10.0056 2.65612 10.4673 2.38389C10.9289 2.11165 11.455 1.96805 11.9909 1.96802H11.9899ZM12.0099 14L11.8829 14.007C11.6399 14.0359 11.4159 14.153 11.2534 14.336C11.0908 14.519 11.0011 14.7553 11.0011 15C11.0011 15.2448 11.0908 15.481 11.2534 15.6641C11.4159 15.8471 11.6399 15.9641 11.8829 15.993L11.9999 16L12.1269 15.993C12.37 15.9641 12.594 15.8471 12.7565 15.6641C12.9191 15.481 13.0088 15.2448 13.0088 15C13.0088 14.7553 12.9191 14.519 12.7565 14.336C12.594 14.153 12.37 14.0359 12.1269 14.007L12.0099 14ZM11.9999 8.00002C11.755 8.00005 11.5186 8.08997 11.3356 8.25273C11.1525 8.41549 11.0356 8.63977 11.0069 8.88302L10.9999 9.00002V11L11.0069 11.117C11.0359 11.3601 11.1529 11.5841 11.3359 11.7466C11.5189 11.9091 11.7552 11.9989 11.9999 11.9989C12.2447 11.9989 12.481 11.9091 12.664 11.7466C12.847 11.5841 12.964 11.3601 12.9929 11.117L12.9999 11V9.00002L12.9929 8.88302C12.9643 8.63977 12.8474 8.41549 12.6643 8.25273C12.4813 8.08997 12.2449 8.00005 11.9999 8.00002Z"
                          fill="#D4026E"/>
                </svg>

            </div>
            <div v-html="text">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: String
    }
}
</script>